<template>
    <div class="scroll_container">
        <div class="stat_container">
            <div class="section_header mt-5 mb-4">
                <h2>{{ workoutPlan.name }}</h2>
                <div class="filter-group">
                    <div class="optionDrops ml-auto" @click.self="workoutFilterDropdown = !workoutFilterDropdown"  v-click-outside="closeWorkoutFilterDropdown">
                        {{ selectedWorkoutFilter }} <i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="workoutFilterDropdown ? 'active' : ''">
                            <ul class="scroll">
                                <li :class="{ active: params.type == 'all' }" @click="params.type = 'all'; selectedWorkoutFilter = 'All Workouts'; workoutFilterDropdown = false;">All Workouts</li>
                                <template v-for="(week, w) of weeks" :key="w">
                                    <li class="list-group">{{ week.name }}</li>
                                    <li :class="{ active: params.week_workout_id == workout.id && params.type != 'all' }" v-for="(workout, w) of week.workouts" :key="w" @click="params.week_workout_id = workout.id; selectedWorkoutFilter = workout.name; workoutFilterDropdown = false; params.type = null;">{{ workout.name }}</li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="workoutPlanReportLoader">
                <quote-loader class="mt-5" />
            </div>
            <div v-else>
                <div class="widgets_wpr widgets_wpr2" v-if="exercises.length">
                    <div class="header">
                        <h2>
                            <svg viewBox="0 0 80 93" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.9358 4.69088C18.9247 3.24462 32.2062 2.96341 38.4733 3.00358C57.371 3.00358 63.381 4.12845 64.0238 4.69088C72.3398 7.46286 76.2585 13.4901 76.9196 20.4792C77.7632 29.3978 71.7372 35.6649 69.6883 37.3522C68.2223 38.5595 68.9652 39.8429 69.6883 40.6063C75.1841 48.7053 76.3973 55.9526 76.317 58.5639C77.5222 72.0623 67.3984 83.2708 63.1801 86.4044C59.8055 88.9112 56.1497 89.4576 54.7437 89.4174C42.9326 90.5021 29.3136 89.5379 24.7338 89.4174C21.07 89.321 18.065 87.3685 17.0205 86.4044C7.49928 78.5705 3.16051 68.4467 3.76312 58.5639C4.36573 48.6812 9.34225 41.6842 10.1508 40.6063C10.8739 39.6421 12.0791 38.6779 10.3918 36.9906C7.37879 34.7007 2.00351 29.1567 3.16051 20.4792C4.60677 9.63226 12.1996 6.4987 15.9358 4.69088Z" :stroke="chartColor" stroke-width="5"/><path d="M17.8241 12.762C12.3284 15.751 11.2758 21.319 11.4365 23.7295C11.7981 27.9477 15.5342 31.0813 16.9805 32.166C18.1375 33.0337 19.7123 32.5275 20.3551 32.166C26.8632 28.6708 31.5636 27.4656 41.0848 27.3451C50.6059 27.2246 57.9578 31.6839 59.5245 32.407C60.778 32.9855 61.7341 32.648 62.0555 32.407C66.4907 29.4181 67.8405 25.3766 67.961 23.7295C68.5636 15.6545 60.7297 12.4005 60.0066 12.1594C59.4281 11.9666 57.8372 11.838 57.1141 11.7979C47.7134 11.1952 22.886 11.7979 21.0782 11.7979C19.632 11.7979 18.3062 12.4406 17.8241 12.762Z" :stroke="chartColor" stroke-width="5"/></svg>
                            Exercise History
                        </h2>
                        <multiselect
                            v-model="params.history_exercise_id"
                            :options="exercises"
                            placeholder="Select Exercise"
                            label="name"
                            valueProp="id"
                            :show-labels="false"
                            :searchable="true"
                            autocomplete="nofill"
                        ></multiselect>
                    </div>
                    <vue-echarts :option="historyChartOption" style="height: 300px" ref="exerciseHistoryChart"></vue-echarts>
                    <span class="chart-no-response" v-if="historyChartScores.length == 0">No response found!</span>
                    <span class="chart_title">Load</span>
                </div>
                <div class="content_card_wpr show">
                    <div class="title">
                        <h4>Completed Workouts<label>{{ workoutPlanReport.total_submissions }}</label></h4>
                        <div class="rate">
                            <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;">
                                <div class="inner_circle">
                                    <div class="score">{{ Math.round((workoutPlanReport.total_submissions * 100) / workoutPlanReport.total_workouts) }}<span>%</span></div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                    <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" :stroke="chartColor" :stroke-dasharray="115" :stroke-dashoffset="115 - (Math.round((workoutPlanReport.total_submissions * 100) / workoutPlanReport.total_workouts) * 1.15)" />
                                </svg>
                            </div>
                            <h6>Completion rate</h6>
                        </div>
                        <button type="button" class="toggle_btn" @click="toggleFunc"><i class="fas fa-chevron-down"></i></button>
                    </div>
                    <div class="section_content">
                        <ul class="list workout_card workout_list">
                            <li v-for="(workout, c) of completed" :key="c">
                                <div class="card_wrapper">
                                    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="33" cy="33" r="32" stroke="#30C13E" stroke-width="2"/>
                                        <path d="M27.6317 47.0433L16.2034 34.5473C15.5168 33.7966 15.5168 32.5793 16.2034 31.8285L18.6898 29.1097C19.3764 28.3589 20.4897 28.3589 21.1763 29.1097L28.8749 37.5276L45.3646 19.4974C46.0512 18.7466 47.1645 18.7466 47.8511 19.4974L50.3375 22.2162C51.0241 22.9669 51.0241 24.1841 50.3375 24.935L30.1182 47.0434C29.4315 47.7942 28.3183 47.7942 27.6317 47.0433V47.0433Z" fill="#30C13E"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">
                                            <span class="date">{{ moment(workout.date).format('ll') }}</span>
                                            <label>{{ workout.name }}</label><i class="fas fa-chevron-down"></i>
                                        </h4>
                                        <div class="details">
                                            <template  v-for="(block, b) of workout.response.blocks" :key="b">
                                                <div class="blocks" v-if="block.exercises && Object.values(block.exercises).length">
                                                    <div class="block_title">
                                                        <div>
                                                            {{ block.name }}
                                                            <h6 v-if="block.perform_type == 2">
                                                                {{block.circuit_settings.amrap ? 'AMRAP' : `${block.circuit_settings.round} rounds`}}
                                                                <div class="quick_info" v-if="block.circuit_settings.amrap">As Many Round As Possible</div>
                                                                <span v-if="block.circuit_settings.work_min || block.circuit_settings.work_sec">&nbsp;| {{block.circuit_settings.work_min ? `${block.circuit_settings.work_min} min` : ''}} {{block.circuit_settings.work_sec ? `${block.circuit_settings.work_sec} sec` : ''}} work </span>
                                                                <span v-if="block.circuit_settings.rest_min || block.circuit_settings.rest_sec">&nbsp;| {{block.circuit_settings.rest_min ? `${block.circuit_settings.rest_min} min` : ''}} {{block.circuit_settings.rest_sec ? `${block.circuit_settings.rest_sec} sec` : ''}} rest</span>
                                                                <span v-if="block.circuit_settings.amrap && block.circuit_settings.has_duration && (block.circuit_settings.block_duration_min || block.circuit_settings.block_duration_sec)">&nbsp;| {{ block.circuit_settings.block_duration_min ? `${block.circuit_settings.block_duration_min} min` : ''  }} {{ block.circuit_settings.block_duration_sec ? `${block.circuit_settings.block_duration_sec} sec` : ''  }}  duration</span>
                                                            </h6>
                                                        </div>
                                                        <span class="tag">Perform as a {{ block.perform_type == 1 ? 'Progression' : 'Circuit' }}</span>
                                                    </div>
                                                    <template v-if="block.exercises && Object.values(block.exercises).length">
                                                        <div class="block_item border-0" v-for="(exercise, e) of Object.values(block.exercises).sort((a, b) => a.order - b.order)" :key="e">
                                                            <div class="details_info">
                                                                <h5>{{ exercise.name }}</h5>
                                                                <table class="task_history" v-if="block.perform_type == 1">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="set">Sets</th>
                                                                            <th>Reps</th>
                                                                            <th v-if="exercise.is_tempo">Tempo</th>
                                                                            <th>Load</th>
                                                                            <th>Rest Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody v-if="block.perform_type == 1 && exercise.is_variable">
                                                                        <template v-if="exercise.response && exercise.response.set_settings">
                                                                            <tr v-for="(set, s) in exercise.response.set_settings" :key="s">
                                                                                <td class="set">{{ s + 1 }}</td>
                                                                                <td>{{ set.reps }}</td>
                                                                                <td v-if="exercise.is_tempo">
                                                                                    {{ set.tempo }}
                                                                                </td>
                                                                                <td>
                                                                                    <div class="flex justify-content-center" v-if="exercise.load_unit_code.toLowerCase() !== '1rm'">
                                                                                        {{ exercise.load_unit_code.toLowerCase() !== 'bw' ? set.load_number : '' }}
                                                                                        &nbsp;{{ exercise.load_unit_code.toLowerCase()  !== 'bw' ? exercise.load_unit_code.toLowerCase() : 'Bodyweight' }}
                                                                                    </div>
                                                                                    <div class="flex justify-content-center" v-else>
                                                                                        <template v-if="set.set_load">
                                                                                            {{ workout.oneRepMaxData[exercise.id] && workout.oneRepMaxData[exercise.id].weight ? set.set_load : '' }}
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            {{ set.load_number }}
                                                                                            <!-- {{ workout.oneRepMaxData[exercise.id] && workout.oneRepMaxData[exercise.id].weight ? Math.round(set.load_number * 0.01 * parseInt(workout.oneRepMaxData[exercise.id].weight)) : '' }} -->
                                                                                        </template>
                                                                                        &nbsp;{{ set.set_load && workout.oneRepMaxData[exercise.id] && workout.oneRepMaxData[exercise.id].weight ? workout.oneRepMaxData[exercise.id].unit : '% ' + exercise.load_unit_code.toLowerCase() }}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="flex justify-content-center">
                                                                                        {{ set.rest_minutes }}:{{ set.rest_seconds }}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr v-for="(set, s) in exercise.set_settings" :key="s">
                                                                                <td class="set">{{ s + 1 }}</td>
                                                                                <td>{{ set.reps }}</td>
                                                                                <td v-if="exercise.is_tempo">
                                                                                    {{ set.tempo }}
                                                                                </td>
                                                                                <td>
                                                                                    <div class="flex justify-content-center" v-if="exercise.load_unit_code.toLowerCase() !== '1rm'">
                                                                                        {{ exercise.load_unit_code.toLowerCase() !== 'bw' ? set.load_number : '' }}
                                                                                        &nbsp;{{ exercise.load_unit_code.toLowerCase()  !== 'bw' ? exercise.load_unit_code.toLowerCase() : 'Bodyweight' }}
                                                                                    </div>
                                                                                    <div class="flex justify-content-center" v-else>
                                                                                        {{ set.set_load && workout.oneRepMaxData[exercise.id] && workout.oneRepMaxData[exercise.id].weight ? Math.round(set.load_number * 0.01 * parseInt(workout.oneRepMaxData[exercise.id].weight)) : set.load_number }}
                                                                                        &nbsp;{{ set.set_load && workout.oneRepMaxData[exercise.id] && workout.oneRepMaxData[exercise.id].weight ? workout.oneRepMaxData[exercise.id].unit : '% ' + exercise.load_unit_code.toLowerCase() }}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="flex justify-content-center">
                                                                                        {{ set.rest_minutes }}:{{ set.rest_seconds }}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                    </tbody>
                                                                    <tbody v-else>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="v_tag" v-if="block.perform_type == 1 && exercise.is_variable">V</span>
                                                                                {{ exercise.sets }}
                                                                            </td>
                                                                            <td>
                                                                                <span class="v_tag" v-if="block.perform_type == 1 && exercise.is_variable">V</span>
                                                                                {{ exercise.reps }}
                                                                            </td>
                                                                            <td v-if="exercise.is_tempo">{{ exercise.tempo }}</td>
                                                                            <td>{{ exercise.set_load ? exercise.set_load :exercise.load }}</td>
                                                                            <td>{{ exercise.rest }}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <table class="task_history" v-else>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Work Time</th>
                                                                            <th>Load</th>
                                                                            <th>Rest Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{{ exercise.work }}</td>
                                                                            <td>{{ exercise.load }}</td>
                                                                            <td>{{ exercise.rest }}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="block_item border-0 no-exercise-wpr">
                                                            <h4>Looks like you don't have any Exercises yet in this block.</h4>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="submit_details">
                                            <span class="success" v-if="workout.is_edited">Edited After Submission</span>
                                            <span class="delay" v-else-if="moment(workout.response.created_at).isAfter(workout.scheduleDate) && Math.abs(moment.duration(moment(workout.scheduleDate).diff(workout.response.created_at)).asHours()) >= 24">Submitted Late</span>
                                            <span v-else>Submitted On Time</span>
                                            <label v-if=" workout.response && workout.response.created_at">Already submitted on {{ moment.utc(workout.response.created_at).tz(contact.timezone).format('ll') }} at {{ moment.utc(workout.response.created_at).tz(contact.timezone).format('LT') }}</label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="content_card_wpr show">
                    <div class="title">
                        <h4>Incomplete Workouts<label>{{ missed.length }}</label></h4>
                        <div class="rate">
                            <div class="score_circle" style="background: #d9d9d9; width:40px; height:40px; padding: 4px;">
                                <div class="inner_circle">
                                    <div class="score">{{ Math.round((missed.length * 100) / workoutPlanReport.total_workouts) }}<span>%</span></div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                    <circle cx="20" cy="20" r="18" stroke-linecap="round" fill="none" stroke-width="4px" :stroke="chartColor" :stroke-dasharray="115" :stroke-dashoffset="115 - (Math.round((missed.length * 100) / workoutPlanReport.total_workouts) * 1.15)" />
                                </svg>
                            </div>
                            <h6>Completion rate</h6>
                        </div>
                        <button type="button" class="toggle_btn" @click="toggleFunc"><i class="fas fa-chevron-down"></i></button>
                    </div>
                    <div class="section_content">
                        <ul class="list workout_card workout_list">
                            <li v-for="(workout, m) of missed" :key="m">
                                <div class="card_wrapper">
                                    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1832 19.8168C18.8702 21.1298 18.8702 23.2585 20.1832 24.5714L28.6119 33.0001L20.1833 41.4287C18.8703 42.7416 18.8703 44.8703 20.1833 46.1833C21.4962 47.4962 23.6249 47.4962 24.9379 46.1833L33.3665 37.7547L41.795 46.1833C43.108 47.4962 45.2367 47.4962 46.5496 46.1833C47.8626 44.8703 47.8626 42.7416 46.5497 41.4287L38.1211 33.0001L46.5497 24.5714C47.8627 23.2585 47.8627 21.1298 46.5497 19.8168C45.2368 18.5039 43.1081 18.5039 41.7951 19.8168L33.3665 28.2455L24.9378 19.8168C23.6249 18.5039 21.4961 18.5039 20.1832 19.8168Z" fill="#EC4242"/>
                                        <circle cx="33" cy="33" r="32" stroke="#EC4242" stroke-width="2"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)">
                                            <span class="date">{{ moment(workout.date).format('ll') }}</span>
                                            <label>{{ workout.name }}</label><i class="fas fa-chevron-down"></i>
                                        </h4>
                                        <div class="details">
                                            <div class="blocks" v-for="(block, b) of workout.blocks" :key="b">
                                                <div class="block_title">
                                                    <div>{{ block.name }}</div>
                                                    <span class="tag">Perform as a {{ block.perform_type == 1 ? 'Progression' : 'Circuit' }}</span>
                                                </div>
                                                <template v-if="block.exercises && Object.values(block.exercises).length">
                                                    <div class="block_item" v-for="(exercise, e) of Object.values(block.exercises).sort((a, b) => a.order - b.order)" :key="e">
                                                        <div class="video_area" :class="{ video_area_flip: exercise.is_flip == 1 }">
                                                            <img :src="exercise.gif_image" :alt="exercise.name">
                                                        </div>
                                                        <div class="details_info">
                                                            <h5>{{ exercise.name }}</h5>
                                                            <h6>
                                                                <span class="v_tag" v-if="block.perform_type == 1 && exercise.is_variable">V</span>
                                                                <span v-if="block.perform_type != 2">{{ exercise.sets }} sets </span>
                                                                <span v-if="exercise.measure_type != 2">&nbsp;{{ exercise.exercise_reps }} reps </span>
                                                                <span v-if="exercise.measure_type == 2 && parseInt(exercise.exercise_minutes)">&nbsp;{{ exercise.exercise_minutes }} min</span>
                                                                <span v-if="exercise.measure_type == 2 && parseInt(exercise.exercise_seconds)">&nbsp;{{ exercise.exercise_seconds }} sec </span>
                                                                <span v-if="exercise.load_unit_code == '1RM'">
                                                                    &nbsp;{{ workout.oneRepMaxData[exercise.id] && workout.oneRepMaxData[exercise.id].weight ? Math.round(exercise.load_number * 0.01 * parseInt(workout.oneRepMaxData[exercise.id].weight)) + ' ' + workout.oneRepMaxData[exercise.id].unit : `${exercise.load_number}% of 1RM` }}
                                                                </span>
                                                                <span v-else-if="exercise.load_unit_code == 'BW'">&nbsp;Bodyweight</span>
                                                                <span v-else>&nbsp;{{ exercise.load_number }} {{ exercise.load_unit_code.toLowerCase() }} </span>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <h4>No exercises found in this block!</h4>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="content_card_wpr show" v-if="newExercises.length">
                    <div class="title">
                        <h4>New Exercises Introduced<label>{{ newExercises.length }}</label></h4>
                        <div class="rate"></div>
                        <button type="button" class="toggle_btn" @click="toggleFunc"><i class="fas fa-chevron-down"></i></button>
                    </div>
                    <div class="section_content">
                        <ul class="list workout_card workout_list">
                            <li v-for="(exercise, e) of newExercises" :key="e">
                                <div class="card_wrapper">
                                    <svg width="235" height="225" viewBox="0 0 235 225" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M73.8685 208.345L67.2476 196.971C64.1705 191.685 58.9877 187.96 52.9967 186.728L39.4473 183.942C30.1071 182.022 23.7568 173.317 24.7801 163.836L26.1685 150.974C26.8052 145.076 24.9764 139.176 21.1149 134.672L12.3533 124.453C6.39132 117.499 6.41562 107.23 12.4105 100.304L21.0424 90.332C24.9483 85.8197 26.8029 79.8869 26.1625 73.9534L24.7801 61.1472C23.7568 51.6667 30.1071 42.9614 39.4473 41.0409L52.9551 38.2634C58.9721 37.0262 64.1725 33.2743 67.2441 27.9545L73.8943 16.4368C78.6495 8.20116 88.874 4.91223 97.5389 8.83105L108.64 13.8518C114.272 16.3989 120.728 16.3989 126.36 13.8518L137.562 8.78559C146.187 4.8845 156.366 8.12469 161.15 16.2942L168.043 28.0674C171.119 33.32 176.277 37.0229 182.237 38.2572L195.581 41.0206C204.909 42.9523 211.245 51.651 210.223 61.1216L208.838 73.9534C208.197 79.8869 210.052 85.8197 213.958 90.332L222.59 100.304C228.584 107.23 228.609 117.499 222.647 124.453L213.885 134.672C210.024 139.176 208.195 145.076 208.831 150.974L210.223 163.862C211.245 173.332 204.909 182.031 195.581 183.963L182.195 186.735C176.261 187.964 171.121 191.64 168.04 196.858L161.175 208.488C156.375 216.619 146.223 219.83 137.62 215.94L126.36 210.847C120.728 208.3 114.272 208.3 108.64 210.847L97.4803 215.894C88.8382 219.803 78.6402 216.542 73.8685 208.345Z" :stroke="chartColor" stroke-width="3"/>
                                        <path d="M83.2755 93V139H75.7705L53.8241 107.667H53.4374V139H45V93H52.5505L74.4742 124.355H74.8835V93H83.2755Z" :fill="chartColor"/>
                                        <path d="M92.4691 139V93H122.762V99.9854H100.907V112.474H121.193V119.459H100.907V132.015H122.944V139H92.4691Z" :fill="chartColor"/>
                                        <path d="M141.172 139L128.027 93H137.101L145.493 126.804H145.925L154.886 93H163.141L172.124 126.826H172.534L180.926 93H190L176.855 139H168.531L159.207 106.724H158.843L149.496 139H141.172Z" :fill="chartColor"/>
                                    </svg>
                                    <div class="info_wpr">
                                        <h4 @click="showDetails($event)"><label>{{ exercise.name }}</label><i class="fas fa-chevron-down"></i></h4>
                                        <div class="details">
                                            <div class="blocks">
                                                <div class="block_item">
                                                    <div class="video_area" :class="{ video_area_flip: exercise.is_flip == 1 }">
                                                        <img :src="exercise.gif_image" :alt="exercise.name">
                                                    </div>
                                                    <div class="details_info">
                                                        <span class="date">{{ moment(exercise.exercise_date).format('ll') }}</span>
                                                        <h5>{{ exercise.name }}</h5>
                                                        <h6>
                                                            <span class="v_tag" v-if="exercise.perform_type == 1 && exercise.is_variable">V</span>
                                                            <span v-if="exercise.perform_type != 2">{{ exercise.sets }} sets </span>
                                                            <span v-if="exercise.measure_type != 2">&nbsp;{{ exercise.exercise_reps }} reps </span>
                                                            <span v-if="exercise.measure_type == 2 && parseInt(exercise.exercise_minutes)">&nbsp;{{ exercise.exercise_minutes }} min</span>
                                                            <span v-if="exercise.measure_type == 2 && parseInt(exercise.exercise_seconds)">&nbsp;{{ exercise.exercise_seconds }} sec </span>
                                                            <span v-if="exercise.load_unit_code == '1RM'">
                                                                &nbsp;{{ exercise.oneRepMaxData && exercise.oneRepMaxData.weight ? Math.round(exercise.load_number * 0.01 * parseInt(exercise.oneRepMaxData.weight)) + ' ' + exercise.oneRepMaxData.unit : `${exercise.load_number}% of 1RM` }}
                                                            </span>
                                                            <span v-else-if="exercise.load_unit_code == 'BW'">&nbsp;Bodyweight</span>
                                                            <span v-else>&nbsp;{{ exercise.load_number }} {{ exercise.load_unit_code.toLowerCase() }} </span>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueEcharts } from 'vue3-echarts'
import { mapState, mapActions } from 'vuex'

import moment from 'moment-timezone'

export default {
    name: 'workout-plan-chart',

    data () {
        return {
            params: {
                filter: 'all',
                week_workout_id: 0,
                history_exercise_id: 0,
                type: 'all',
                contact_id: 0,
            },
            workoutFilterDropdown: false,
            selectedWorkoutFilter: 'All Workouts',
            workoutPlan: {},
            completed: [],
            missed: [],
            workouts: [],
            exercises: [],
            weeks: [],
            newExercises: [],
            moment,
            isMounted: false,
            workoutPlanReport: {},
            historyChartOption : {
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        fontSize: 11
                    },
                    formatter: function (params) {
                        return params[0].name + ': ' + params[0].value[1] + ' ' + params[0].value[2];
                    }
                },
                grid: {
                    left: 25,
                    right: 25,
                    bottom: 80,
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    axisLine:{
                        show: false,
                        lineStyle:{
                            color: "#E8E7E7"
                        }
                    },
                    axisLabel: {
                        fontSize: 11,
                        color: '#4D4D4D'
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    minInterval: 1,
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: 'rgba(47,127,237,0.15)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(58,77,233,0)'
                                    }
                                ],
                                global: false
                            },
                        },
                        lineStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#2F7FED'
                                    },
                                    {
                                        offset: 1,
                                        color: '#88CBEE'
                                    }
                                ],
                                global: false
                            }
                        }
                    },
                ],
                dataZoom: [{
                    start: 0,
                    end: 100,
                    moveHandleSize: 10,
                    moveHandleStyle: {
                        color: "#999",
                    },
                    height: 0,
                    showDataShadow: false,
                    showDetail: false,
                    handleStyle: {
                        opacity: 0
                    },
                }],
                media: [
                    {
                        query: {
                            maxWidth: 991
                        },
                        option: {
                            dataZoom: [{
                                start: 0,
                                end: 100,
                            }],
                        }
                    },
                    {
                        query: {
                            maxWidth: 767
                        },
                        option: {
                            dataZoom: [{
                                start: 0,
                                end: 100,
                            }],
                        }
                    },
                    {
                        query: {
                            maxWidth: 499
                        },
                        option: {
                            dataZoom: [{
                                start: 60,
                                end: 100,
                            }],
                        }
                    },
                ],
            },
            historyChartScores: [],
            allExercises: [],
            chartColor: '#2F7EED'
        }
    },

    props: {
        contact: Object,
        planId: Number,
    },

    watch: {
        'params.filter' () {
            const vm = this;

            if (vm.isMounted) {
                vm.handleWorkoutPlanReport(vm.planId);
            }
        },

        'params.week_workout_id' (id) {
            const vm = this;

            if (vm.isMounted && vm.params.type != 'all') {
                const workout = vm.workouts.filter(w => w.id == id)[0];

                if (workout) {
                    vm.exercises = workout.exercises;
                    vm.params.history_exercise_id = vm.exercises && vm.exercises.length ? vm.exercises[0].id : 0;
                }
            }
        },

        'params.type' (type) {
            const vm = this;

            if (vm.isMounted && type == 'all') {
                vm.exercises = vm.allExercises;
                vm.params.history_exercise_id = vm.exercises && vm.exercises.length ? vm.exercises[0].id : 0;
            } else {
                const workout = vm.workouts.filter(w => w.id == vm.params.week_workout_id)[0];

                if (workout) {
                    vm.exercises = workout.exercises;

                    vm.params.history_exercise_id = vm.exercises && vm.exercises.length ? vm.exercises[0].id : 0;
                }
            }
        },

        'params.history_exercise_id' (exercise) {
            const vm = this;

            if (vm.isMounted) {
                vm.handleWorkoutExerciseHistory(exercise);
            }
        },

        settings: {
            handler (settings) {
                const vm = this;

                if (vm.settings.chart_settings && vm.settings.chart_settings.graph_accent_color) {
                    vm.chartColor = vm.settings.chart_settings.graph_accent_color;

                    vm.handleWorkoutExerciseHistory();
                }
            },
            deep: true
        }
    },

    computed: mapState({
        workoutPlanReportLoader: state => state.workoutModule.workoutPlanReportLoader,
        historyChartLoader: state => state.workoutModule.historyChartLoader,
        settings: state => state.clientPortalModule.clientPortal,

    }),

    components: {
        VueEcharts,
    },

    mounted () {
        const vm = this;

        moment.tz.setDefault(vm.contact.timezone);

        if (vm.settings.chart_settings && vm.settings.chart_settings.graph_accent_color) {
            vm.chartColor = vm.settings.chart_settings.graph_accent_color;
        }

        if (!vm.settings.id) {
            vm.getClientPortal();
        }

        vm.isMounted = false;

        vm.handleWorkoutPlanReport(vm.planId);

        setTimeout(() => {
            vm.isMounted = true;
        }, 1000)
    },

    methods: {
        ...mapActions({
            getWorkoutPlanReport: 'workoutModule/getWorkoutPlanReport',
            getWorkoutPlanExerciseHistory: 'workoutModule/getWorkoutPlanExerciseHistory',
            getClientPortal: 'clientPortalModule/getClientPortal',
        }),

        closeWorkoutFilterDropdown () {
            const vm = this;

            vm.workoutFilterDropdown = false;
        },

        handleWorkoutPlanReport (planId) {
            const vm = this;

            vm.params.contact_id = vm.contact.id;

            if (planId) {
                vm.getWorkoutPlanReport({ plan_id: planId, params: vm.params }).then((resp) => {
                    if (resp) {
                        vm.workoutPlanReport    = resp;
                        vm.workoutPlan          = resp.workoutPlan;
                        vm.completed            = resp.completed;
                        vm.missed               = resp.missed;
                        vm.workouts             = resp.workouts;
                        vm.newExercises         = resp.newExercises;
                        vm.weeks                = resp.weeks;
                        vm.allExercises         = resp.exercises;

                        if (vm.workouts && vm.workouts.length) {
                            if (vm.exercises.length == 0) {
                                if (vm.params.week_workout_id == 0) {
                                    vm.exercises = vm.allExercises;
                                } else {
                                    vm.exercises = vm.workouts[0].exercises;
                                }
                            }
                        } else {
                            vm.selectedWorkoutFilter    = 'All Workouts';
                        }

                        if (!vm.params.history_exercise_id) {
                            vm.params.history_exercise_id = vm.exercises && vm.exercises.length ? vm.exercises[0].id : 0;
                            vm.params.week_workout_id = vm.exercises && vm.exercises.length ? vm.exercises[0].week_workout_id : 0;
                        }

                        vm.handleWorkoutExerciseHistory();
                    }
                })
            }
        },

        handleWorkoutExerciseHistory () {
            const vm = this;

            vm.params.contact_id = vm.contact.id;

            if (vm.planId && vm.params.week_workout_id && vm.params.history_exercise_id) {
                vm.getWorkoutPlanExerciseHistory({plan_id: vm.planId, params: vm.params }).then((resp) => {
                    if (resp) {
                        vm.refreshHistoryChart(resp);
                    }
                })
            }
        },

        refreshHistoryChart (scores) {
            const vm    = this;
            const chart = vm.$refs.exerciseHistoryChart;

            vm.historyChartScores = scores;

            vm.historyChartOption.series[0].data    = [];
            vm.historyChartOption.series[0].color   = vm.chartColor;
            vm.historyChartOption.series[0].lineStyle.color.colorStops[0].color   = vm.chartColor;
            vm.historyChartOption.series[0].lineStyle.color.colorStops[1].color   = vm.chartColor+'60';
            vm.historyChartOption.series[0].areaStyle.color.colorStops[0].color   = vm.chartColor+'25';
            vm.historyChartOption.series[0].areaStyle.color.colorStops[1].color   = vm.chartColor+'05';

            Object.values(scores).forEach((score) => {
                const data = [];

                data.push(score.xAxix);
                data.push(score.load);
                data.push(score.unit);

                vm.historyChartOption.series[0].data.push(data);
            });

            const scoreLenght = Object.values(vm.historyChartOption.series[0].data).length

            vm.historyChartOption.media[0].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
            vm.historyChartOption.media[1].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
            vm.historyChartOption.media[2].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 3);

            chart.refreshChart();
        },

        toggleFunc (ev) {
            let el = ev.currentTarget.closest('.content_card_wpr');
            const subEl = el.querySelectorAll('.list li');

            if (el.classList.contains('show')) {
                el.classList.remove('show');

                for (let i = 0; i < subEl.length; i++) {
                    subEl[i].classList.remove('show');
                }
            } else {
                el.classList.add('show');
            }
        },

        showDetails (ev) {
            const el = ev.currentTarget.closest('li');
            const actionEl = ev.currentTarget.parentNode.querySelector('.submit_details');

            if (el.classList.contains('show')) {
                el.classList.remove('show');

                if (actionEl) {
                    actionEl.classList.remove('show');
                }
            } else {
                el.classList.add('show');

                if (actionEl) {
                    actionEl.classList.add('show');
                }
            }
        },
    },
}
</script>


<style scoped>
.scroll_container{
    height: 100vh;
    background: #f5f5f5;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 30px 0 50px 0;
}

.scroll_container::-webkit-scrollbar {
    width: 8px;
}

.scroll_container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #d3d3d3;
}

.preview_area.show_sidebar {
    width: 100%;
    left: auto;
    height: 100vh;
}

.dropdown_wpr ul li {
    border-bottom: 1px solid #f3f3f3;
    border-right: 0;
    cursor: pointer;
    position: relative;
    padding: 8px 14px;
}
.stat_container {
    max-width: 1000px;
    padding: 0 20px 100px 20px;
    margin: 0 auto;
}

.section_header .optionDrops {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: left;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.section_header .optionDrops i {
    margin-left: 10px;
    color: #7a7a7a;
    pointer-events: none;
}

.section_header .optionDrops .dropdown_wpr {
    min-width: 160px;
    left: auto;
    right: 1px;
    overflow: hidden;
}

.section_header .optionDrops ul li {
    flex: 1 1 auto;
    border: 0;
    background: transparent;
}

.widgets_wpr {
    flex: 1 1 auto;
    padding: 20px 30px;
    border-radius: 8px;
    height: auto;
    margin-bottom: 30px;
}

.widgets_wpr ul li {
    height: 28px;
    width: auto;
}

.widgets_wpr ul li span {
    width: 22px;
}

.widgets_wpr ul li select {
    font-size: 11px;
    line-height: 16px;
    padding: 0 27px 0 10px;
}

:deep(.widgets_wpr2 .header) {
    display: flex;
    justify-content: space-between;
}

:deep(.widgets_wpr2 .header .multiselect) {
    height: 28px;
    min-height: 28px;
    width: 320px;
    margin: 0;
}

:deep(.widgets_wpr2 .header .multiselect .multiselect__select) {
    height: 30px;
    top: 0 !important;
    width: 35px;
    border-left: 1px solid #CBCDD3;
    font-family: "Font Awesome 5 Free";
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-weight: 900;
    padding: 8px;
}

:deep(.widgets_wpr2 .header .multiselect.multiselect--active .multiselect__select) {
    transform: none;
}
:deep(.widgets_wpr2 .header .multiselect.multiselect--active .multiselect__select:before) {
    content: '\f106';
}

:deep(.widgets_wpr2 .header .multiselect),
:deep(.widgets_wpr2 .header .multiselect .multiselect__input),
:deep(.widgets_wpr2 .header .multiselect .multiselect-single-label),
:deep(.widgets_wpr2 .header .multiselect .multiselect__tags .multiselect__input),
:deep(.widgets_wpr2 .header .multiselect .multiselect__tags .multiselect-single-label),
:deep(.widgets_wpr2 .header .multiselect .multiselect__input::placeholder),
:deep(.widgets_wpr2 .header .multiselect .multiselect-single-label::placeholder),
:deep(.widgets_wpr2 .header .multiselect .multiselect__input::-ms-input-placeholder),
:deep(.widgets_wpr2 .header .multiselect .multiselect-single-label::-ms-input-placeholder) {
    font-size: 11px !important;
}

:deep(.widgets_wpr2 .header .multiselect .multiselect-dropdown ul.multiselect-options li span) {
    font-size: 11px !important;
}

:deep(.widgets_wpr2 .header .multiselect .multiselect-dropdown ul.multiselect-options) {
    gap: 0;
}

:deep(.widgets_wpr2 .header .multiselect .multiselect-clear) {
    display: none;
}

:deep(.widgets_wpr2 .header .multiselect .multiselect__select:before) {
    content: '\f107';
    border-style: none;
    top: 0;
    color: #7a7a7a;
    font-size: 13px;
}

:deep(.widgets_wpr2 .header .multiselect .multiselect__tags) {
    height: 28px;
    min-height: 30px !important;
    font-size: 11px !important;
    padding: 5px 10px !important;
}
:deep(.widgets_wpr2 .header .multiselect .multiselect-single-label) {
    font-size: 11px;
}

.widgets_wpr .header h2,
.widgets_wpr2 .header h2 {
    font-size: 18px;
    line-height: 25px;
}

.widgets_wpr .header h2 svg,
.widgets_wpr2 .header h2 svg {
    height: 20px;
    width: auto;
    margin-right: 15px;
}

.widgets_wpr .header .lb_tabs,
.widgets_wpr2 .header .lb_tabs {
    display: flex;
    gap: 5px;
    margin: auto 0;
}

.widgets_wpr .header .lb_tabs button,
.widgets_wpr2 .header .lb_tabs button {
    background: transparent;
    height: 27px;
    padding: 5px 10px;
    color: #121525;
    font-size: 11px;
    line-height: 1;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    outline: 0;
    text-transform: capitalize;
}

.widgets_wpr .header .lb_tabs button.active,
.widgets_wpr2 .header .lb_tabs button.active {
    background: #dbdbdb;
}

.widgets_wpr span.chart_title,
.widgets_wpr2 span.chart_title {
    width: 70px;
    display: flex;
    justify-content: center;
    font-size: 10px;
    left: -20px;
}

.content_card_wpr {
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
    background: #fff;
    margin-bottom: 30px;
    overflow: hidden;
}

.content_card_wpr .title {
    font-size: 18px;
    line-height: 25px;
    color: #121525;
    font-weight: 400;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
}

.content_card_wpr .title span {
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    color: #777;
    margin-left: auto;
}

.content_card_wpr .title h4 {
    font-size: 16px;
    line-height: 25px;
    color: #121525;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.content_card_wpr .title h4 i {
    font-size: 14px;
    color: #2f7eed;
}

.content_card_wpr .title h4 label {
    font-size: 11px;
    line-height: 20px;
    border-radius: 12px;
    color: #2f7eed;
    background: #e3eefd;
    font-weight: 500;
    display: inline-block;
    padding: 0 6px;
}

.content_card_wpr .title .rate {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
}

.content_card_wpr .title .rate .score {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
}

.content_card_wpr .title .rate .score span {
    font-size: 8px;
}

.content_card_wpr .title .rate h6 {
    font-size: 9px;
    line-height: 10px;
    color: #5a5a5a;
    font-weight: 400;
    margin-top: 5px;
    max-width: 100px;
    text-align: center;
    display: none;
}

.content_card_wpr .title .toggle_btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #eee;
    font-size: 10px;
    color: #5a5a5a;
    margin-left: 15px;
    transition: all 0.5s ease-in-out;
}

.content_card_wpr.show .title .toggle_btn {
    transform: rotate(-180deg);
}

.content_card_wpr .section_content {
    padding: 0;
    width: 100%;
    max-height: 55px;
    transition: all 0.5s ease-in-out;
}

.content_card_wpr.show .section_content {
    max-height: 1000vh;
    overflow: overlay;
}

.content_card_wpr.show .section_content::-webkit-scrollbar {
    width: 3px;
}

.content_card_wpr.show .section_content::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 2px;
}

.content_card_wpr.show .section_content {
    max-height: 1000vh;
    overflow: overlay;
}

.section_content .list>li {
    display: flex;
    padding: 0 30px;
    min-height: 55px;
    /* border-bottom: 1px solid #f5f5f5; */
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
}

.section_content .list li .trends {
    margin-left: 10px;
    background: transparent;
}

.section_content .list li .amt {
    font-weight: 500;
    color: #121525;
    display: flex;
    gap: 10px;
    margin-left: auto;
}

.section_content .list li .soon {
    font-weight: 400;
    color: #999;
    margin-left: auto;
}

.workout_card li .info_wpr {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-bottom: 15px;
    gap: 0;
    transition: all 0.3s ease-in-out;
}

.workout_card li.show .info_wpr {
    gap: 15px;
}

.workout_card li.show .info_wpr h4 i {
    transform: rotate(-180deg);
}

.workout_card li a {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #2f7eed;
    margin: 0 0 0 auto;
    cursor: pointer;
}

.workout_card li.show .info_wpr .details {
    max-height: max-content;
}

.workout_card li:last-child .info_wpr {
    border-bottom: 0;
}

.workout_card li .card_wrapper {
    border-radius: 6px;
    display: flex;
    gap: 15px;
    /* cursor: pointer; */
    padding: 15px 0 0 0;
    width: 100%;
}

.workout_card li .card_wrapper svg {
    flex: 0 0 20px;
    height: 20px;
}

.workout_card li .info_wpr h4 {
    font-size: 13px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.workout_card li .info_wpr h4 .date {
    font-weight: 500;
    color: #121525;
    padding-right: 10px;
}

.workout_card li .info_wpr h4 label {
    margin-right: auto;
}

.workout_card li .info_wpr span.tag {
    /* background: #ecf4ff; */
    padding: 0 6px;
    border-radius: 8px;
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    color: #2f7eed;
    margin-left: auto;
}

.workout_card li .info_wpr h4 i {
    font-size: 10px;
    margin-left: 10px;
    color: #999;
    transition: all 0.3s ease-in-out;
    display: none;
}

.content_card_wpr.show .workout_card li .info_wpr h4 i {
    display: block;
}

.workout_card li .info_wpr h6 {
    font-size: 11px;
    line-height: 15px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 -5px 5px -5px; */
    gap: 5px;
}

.workout_card li .info_wpr h6 span {
    padding: 0 5px 0 0;
}

.workout_card li .info_wpr h6 span:not(:last-child) {
    border-right: 1px solid #d9d9d9;
}

.workout_card li .info_wpr .blocks {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 10px;
    flex: 1 1 auto;
}

.workout_card li .info_wpr .blocks .block_title {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.workout_card li .info_wpr .blocks .block_title h6 {
    font-weight: 400;
    margin-top: 5px;
}

.workout_card li .info_wpr .block_item {
    border: 1px solid #f5f5f5;
    background: #fff;
    border-radius: 5px;
    border-radius: 10px;
    display: flex;
    gap: 12px;
}

.workout_card li .info_wpr .block_item .date {
    font-size: 11px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
}

.workout_card li .info_wpr .block_item h5 {
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
}

.workout_card li .info_wpr .block_item h6 {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
}

.workout_card li .info_wpr .block_item h6 .v_tag {
    width: 14px;
    height: 14px;
    background: #999;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
    color: #fff;
    margin-right: 5px;
    border-radius: 50%;
    padding: 0;
}

table.task_history {
    width: 100%;
    border-spacing: 8px;
    background: #f5f5f5;
    border-radius: 5px;
    padding: 0 5px 5px 5px;
}

.task_history th,
.task_history td {
    padding: 10px 0;
    border-radius: 3px;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
}

.task_history td {
    width: 23%;
    background: #fff;
    color: #777;
}

.task_history td.set {
    width: 80px;
    background: #fbfbfb;
}

.workout_card li .info_wpr .details {
    display: flex;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    gap: 15px;
    transition: all 0.3s ease-in-out;
}

.workout_card li .info_wpr .details .rest_info {
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
}

.workout_card li .info_wpr .details .redirect_btn {
    font-size: 11px;
    line-height: 14px;
    padding: 6px 12px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    margin: 0 0 0 auto;
    text-decoration: none;
}

.workout_card li .video_area {
    width: 25%;
    border-right: 1px solid #f5f5f5;
}

.workout_card li .video_area img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.workout_card li .info_wpr .details .details_info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
}

.stat_list {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    column-gap: 20px;
    row-gap: 10px;
}

.stat_list li {
    width: calc(50% - 10px);
}

.stat_list .stat_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 15px;
    gap: 10px;
}

.stat_list .stat_card .rate {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}

.stat_list .stat_card .rate .score {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
}

.stat_list .stat_card .rate .score span {
    font-size: 8px;
}

.stat_list .stat_card .rate h6 {
    font-size: 9px;
    line-height: 12px;
    font-weight: 500;
    color: #5a5a5a;
}

.stat_list .stat_card .rate .avr_score {
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
    color: #2f7eed;
    padding: 3px 12px;
    border: 1px solid #bed8ff;
    border-radius: 12px;
}

.stat_list .stat_card .stat_info h5 {
    font-size: 15px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 500;
    margin-bottom: 10px;
}

.stat_list .stat_card .stat_info h3 {
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.stat_list .stat_card .stat_info h3 i {
    font-size: 13px;
    color: #2f7eed;
}

.stat_list .stat_card .stat_info h3 .field_wpr {
    width: 85px;
}

.stat_list .stat_card .stat_info h3 .field_wpr .multiselect {
    height: 20px;
    min-height: 20px;
    border: 0;
}

.stat_list .stat_card .stat_info h3 .field_wpr .multiselect :deep(.multiselect-caret) {
    margin-right: 5px;
    height: 11px;
}

.stat_list .stat_card .stat_info h3 .field_wpr .multiselect :deep(.multiselect-clear) {
    display: none;
}

.stat_list .stat_card .stat_info h3 .field_wpr .multiselect :deep(.multiselect-multiple-label),
.stat_list .stat_card .stat_info h3 .field_wpr .multiselect :deep(.multiselect-placeholder),
.stat_list .stat_card .stat_info h3 .field_wpr .multiselect :deep(.multiselect-single-label) {
    padding: 0 5px;
    font-size: 9px;
    line-height: 10px;
}

.stat_list .stat_card .stat_info h3 .field_wpr .multiselect :deep(.multiselect-dropdown ul.multiselect-options li) {
    font-size: 9px;
    line-height: 10px;
    padding: 5px;
}

.filter-group {
    display: flex;
    gap: 15px;
}

.section_header .optionDrops ul li.list-group {
    font-weight: bold;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    cursor: default;
    background: #f5f5f5;
}

.section_header .optionDrops ul li.active {
    font-weight: 600;
}

ul.scroll {
   height: 250px;
   overflow-y: scroll;
   scroll-behavior: smooth;
}

ul.scroll::-webkit-scrollbar {
    width: 4px;
}

ul.scroll::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    border-radius: 2px;
}

.workout_card li .info_wpr .submit_details {
    display: none;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.workout_card li .info_wpr .submit_details.show {
    display: flex;
}

.workout_card li .info_wpr .submit_details label {
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #5a5a5a;
}

.workout_card li .info_wpr .submit_details span {
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #2f7eed;
    background: #f0f6ff;
    padding: 4px 10px;
    border-radius: 12px;
}

.workout_card li .info_wpr .submit_details span.delay {
    color: #f2a31d;
    background: #fff6e6;
}

.workout_card li .info_wpr .submit_details span.success {
    color: #23993e;
    background: #effff2;
}

.workout_card li .info_wpr .submit_details span.missed {
    color: #eb1414;
    background: #fff2f2;
}

.workout_card li .info_wpr .submit_details .redirect_btn {
    font-size: 11px;
    line-height: 14px;
    padding: 6px 12px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    margin: 0 auto 0 0;
    text-decoration: none;
}

.chart-no-response {
    width: 100%;
    position: absolute;
    top: 50%;
    text-align: center;
    left: 0;
}

.workout_list .block_item .video_area_flip {
    width: 33%;
    line-height: 0;
}
.workout_list .block_item .video_area_flip img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(-1, 1);
}

.workout_card li .info_wpr .block_item.no-exercise-wpr {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

@media(max-width: 499px) {
    .content_card_wpr .title {
        padding: 15px 20px;
    }

    .section_content .list>li {
        padding: 0 20px;
    }

    .workout_card li .info_wpr h4 .date {
        width: 100%;
        font-size: 11px;
        line-height: 14px;
    }

    .content_card_wpr .title .rate .score_circle {
        display: none;
    }

    .workout_card li .info_wpr .submit_details{
        flex-direction: column;
        align-items: flex-start;
    }
    .workout_card li .info_wpr .submit_details span{
        margin-bottom: 10px;
    }
    .workout_card li .info_wpr .submit_details .redirect_btn{
        padding: 6px 0;
        margin-bottom: 10px;
    }
    .workout_card li .info_wpr .submit_details .redirect_btn.missed{
        padding: 6px 0;
        margin: 0 0 10px 0;
    }
}
</style>